import React from "react"
import styled from "styled-components"
import { media } from "./helpers"

const WrapperContainer = styled.div`
  margin: 0 auto;
  max-width: 90vw;

  &.xss {
    max-width: 90vw;
    ${media.small`max-width: 600px;`};
  }

  &.xs {
    max-width: 90vw;

    ${media.medium`max-width: 690px;`};
  }

  &.sm {
    ${media.medium`max-width: 75vw;`};
  }

  &.md {
    ${media.medium`max-width: 85vw;`};
  }

  &.desktop-normal {
    ${media.medium`max-width: 90vw;`};
  }
  &.fw {
    max-width: 100vw;
  }

  &.flex {
    display: flex;
  }
  &.flex-wrap {
    display: flex;
    flex-wrap: wrap;
  }
  &.md-flex-wrap {
    ${media.medium`display: flex; flex-wrap: wrap;`};
  }
`

const Wrapper = ({ size, children, flex, mt, rel, shadow, styleClass }) => {
  let classes = []

  if (size != null) {
    classes.push(size)
  }

  if (styleClass != null) {
    classes.push("wrapper-class")
  }

  if (flex != null) {
    classes.push("flex-wrap")
  }

  if (mt != null) {
    classes.push(mt)
  }

  if (rel != null) {
    classes.push(rel)
  }

  if (shadow != null) {
    classes.push("shadow")
  }

  classes = classes.join(" ")
  return <WrapperContainer className={classes}>{children}</WrapperContainer>
}

export default Wrapper
