import React, { useState } from "react"
import styled from "styled-components"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import Logo from "../images/logo.inline.svg"
import Wrapper from "./wrapper"
import { media, TransitionMixin } from "./helpers"
import Down from "../images/chevron-down.inline.svg"
const FooterContainer = styled.footer`
  border-top: 1px solid #eaeaea;
  padding: 50px 0;
  .flex-wrap {
    .footer-col {
      flex: 1 1 50%;
      margin-bottom: 40px;
      display: none;
      ${media.medium`flex: 1; display: block;`};

      &:nth-child(1) {
        order: 4;
        ${media.medium`order: 1;`};
      }
      &:nth-child(2) {
        order: 3;
        flex: 1 1 100%;
        display: block !important;
        ${media.medium`order: 2; flex: 1;`};
      }
      &:nth-child(3) {
        order: 2;
        ${media.medium`order: 3;`};
      }
      &:nth-child(4) {
        order: 3;
        ${media.medium`order: 4; `};
      }
      &:nth-child(5) {
        order: 2;
        flex: 1 1 100%;
        ${media.medium`order: 5; flex: 1.5;`};
      }

      .footer-logo {
        margin-bottom: 10px;
        svg {
          path {
            fill: #1e1e1e;
          }
        }
      }

      h4 {
        margin: 0 0 10px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        line-height: 1.4;
        li {
          a {
            text-decoration: none;
            font-size: 13px;
            color: #4d4d4d;
            ${TransitionMixin(".25s")};

            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      &.wide {
        display: block;
        p {
          margin: 10px 0 15px;
          font-size: 13px;
          color: #4d4d4d;
        }

        .btn-wrap {
          display: flex;
          max-width: 250px;
          a {
            text-decoration: none;
            flex: 1;
            &:first-child {
              img {
                margin-right: 20px;
              }
            }
            .gatsby-image-wrapper {
              max-width: 110px;
            }
          }
        }
      }
    }
  }
  .copyright {
    p {
      margin: 0px;
      font-size: 12px;
      color: #4d4d4d;
    }
  }
  .footer-acc-container {
    ${media.medium`display: none;`};
    margin-bottom: 40px;
    flex: 1;

    .acc-item {
      padding: 15px 0;
      border-bottom: 1px solid #efefef;

      &:first-child {
        border-top: 1px solid #efefef;
      }
      .acc-title {
        a {
          color: #000;
          text-decoration: none;
          display: flex;
          align-items: center;

          h4 {
            flex: 2;
            margin: 0px;
          }
          span {
            flex: 1;
            text-align: right;

            svg {
              stroke-width: 1px;
              ${TransitionMixin(".25s")};
            }
          }
          &.active {
            span {
              svg {
                transform: rotate(180deg);
              }
            }
          }
        }
      }
      .acc-body {
        ul {
          padding: 0px;
          margin: 0px;
          list-style: none;
          overflow: hidden;
          max-height: 0px;
          ${TransitionMixin(".25s")};

          li {
            padding: 0 0 15px;

            a {
              color: #777;
              font-size: 14px;
              text-decoration: none;
            }
          }
        }

        &.active {
          ul {
            max-height: 1000px;
            padding: 20px 0;
          }
        }
      }
    }
  }
`

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      apple: file(relativePath: { eq: "ap-n.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      google: file(relativePath: { eq: "gp-n.png" }) {
        childImageSharp {
          fluid(maxWidth: 250) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allWordpressAcfOptions {
        edges {
          node {
            id
            options {
              link_list_2_title
              link_list_3_title
              link_list_3 {
                link {
                  target
                  title
                  url
                }
              }
              link_list_2 {
                link {
                  target
                  title
                  url
                }
              }
              link_list_1 {
                link {
                  target
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  const [activeAccordion, setActiveAccordion] = useState(-1)

  function toggleAcc(e, index) {
    e.preventDefault()
    console.log("index", index)
    let clickedItem = document.querySelectorAll(".acc-item .acc-title")[index]

    console.log("yuh", clickedItem)
    if (index == activeAccordion) {
      // close acc
      //   clickedItem.querySelector("a").classList.remove("active")
      //   clickedItem.querySelector(".acc-body").classList.remove("active")
      setActiveAccordion(-1)
    } else {
      setActiveAccordion(index)
    }
  }
  let d = new Date()
  let date = d.getFullYear()
  let footerData = data.allWordpressAcfOptions.edges[0].node.options
  console.log("footer", footerData, data)
  return (
    <FooterContainer>
      <Wrapper flex size="mobile-md">
        <div className="footer-acc-container">
          <div className="acc-item">
            <div className="acc-title">
              <a
                className={activeAccordion == 0 ? "active" : ""}
                onClick={e => toggleAcc(e, 0)}
                href="#"
              >
                <h4>{footerData.link_list_2_title}</h4>
                <span>
                  <Down />
                </span>
              </a>
            </div>
            <div
              className={activeAccordion == 0 ? "active acc-body" : "acc-body"}
            >
              <ul>
                {footerData.link_list_2.map(item => {
                  let link = item.link
                  return (
                    <li>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>

          <div className="acc-item">
            <div className="acc-title">
              <a
                className={activeAccordion == 1 ? "active" : ""}
                onClick={e => toggleAcc(e, 1)}
                href="#"
              >
                <h4>{footerData.link_list_3_title}</h4>
                <span>
                  <Down />
                </span>
              </a>
            </div>
            <div
              className={activeAccordion == 1 ? "active acc-body" : "acc-body"}
            >
              <ul>
                {footerData.link_list_3.map(item => {
                  let link = item.link
                  return (
                    <li>
                      <a href={link.url}>{link.title}</a>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-col">
          <div className="footer-logo">
            <Logo />
          </div>
          <ul>
            {footerData.link_list_1.map(item => {
              let link = item.link
              return (
                <li>
                  <a href={link.url}>{link.title}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer-col">
          <h4>{footerData.link_list_2_title}</h4>
          <ul>
            {footerData.link_list_2.map(item => {
              let link = item.link
              return (
                <li>
                  <a href={link.url}>{link.title}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer-col">
          <h4>{footerData.link_list_3_title}</h4>
          <ul>
            {footerData.link_list_3.map(item => {
              let link = item.link
              return (
                <li>
                  <a href={link.url}>{link.title}</a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer-col wide">
          <h4>Get ChargeTree Mobile app</h4>
          <p>Download the app for both iOS and Android platforms.</p>
          <div className="btn-wrap">
            <a href="#">
              <Img fluid={data.apple.childImageSharp.fluid} />
            </a>
            <a href="#">
              <Img fluid={data.google.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </Wrapper>
      <Wrapper size=" mobile-md">
        <div className="copyright">
          <p>&copy; {date} ChargeTree</p>
        </div>
      </Wrapper>
    </FooterContainer>
  )
}

export default Footer
