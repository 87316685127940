import { Link, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import Logo from "../images/logo.inline.svg"
import styled from "styled-components"
import Wrapper from "./wrapper"
import { media, TransitionMixin } from "./helpers"

const HeaderContainer = styled.header`
  position: fixed;
  width: 100%;
  z-index: 1000;
  border-bottom: 1px solid transparent;
  ${TransitionMixin(".25s")};
  &.active {
    background-color: #fff;
    border-color: #efefef;
    .flex-wrap {
      .logo-wrap {
        a {
          svg {
            path {
              fill: #2a2a2a;
            }
          }
        }
      }
      .links-wrap {
        .mobile-trigger-container {
          .hamburger-inner,
          .hamburger-inner::before,
          .hamburger-inner::after {
            background-color: #2a2a2a;
          }
          .hamburger.is-active .hamburger-inner,
          .hamburger.is-active .hamburger-inner::before,
          .hamburger.is-active .hamburger-inner::after {
            background-color: #2a2a2a;
          }
        }
        nav.desktop-nav {
          ul {
            li {
              &:last-child {
                a {
                  color: #fff;
                  background-color: #2a2a2a;
                  border: 1px solid #2a2a2a;
                  ${TransitionMixin(".25s")};

                  &:hover {
                    background-color: #fff;
                    color: #2a2a2a;
                  }
                }
              }
              a {
                color: #2a2a2a;
              }
            }
          }
        }
      }
    }
  }

  .flex-wrap {
    padding: 20px 0;
    .logo-wrap {
      flex: 1;

      a {
        svg {
          width: 100%;
          height: auto;
          max-width: 200px;
          path {
            ${TransitionMixin(".25s")};
          }
        }
      }
    }

    .links-wrap {
      flex: 1;
      align-self: center;

      .mobile-trigger-container {
        display: block;
        text-align: right;

        ${media.medium`display: none;`}
      }

      nav.desktop-nav {
        text-align: right;
        display: none;

        ${media.medium`display: block;`}

        ul {
          margin: 0px;
          padding: 0px;
          list-style: none;

          li {
            display: inline-block;
            margin-right: 40px;

            &:last-child {
              margin-right: 0px;

              a {
                color: #2a2a2a;
                background-color: #fff;
                display: inline-block;
                padding: 10px 0;
                width: 140px;
                text-align: center;
                border-radius: 20px;
                border: 1px solid transparent;
                ${TransitionMixin(".25s")};

                &:hover {
                  background-color: #2a2a2a;
                  color: #fff;
                  opacity: 1;
                }
              }
            }
            a {
              font-size: 17px;
              line-height: 1;
              color: #fff;
              text-decoration: none;
              font-weight: 300;
              font-family: "Montserrat", sans-serif !important;
              ${TransitionMixin(".25s")};

              &:hover {
                opacity: 0.7;
              }
            }
          }
        }
      }
    }
  }
`

const MobileMenuContainer = styled.div`
  position: absolute;
  top: 70px;
  background-color: #fff;
  height: 90vh;
  width: 100%;
  transform: translateX(-100%);
  opacity: 0;
  ${TransitionMixin(".25s")};
  ${media.medium`display: none;`};

  &.active {
    transform: translateX(0);
    opacity: 1;
  }

  ul {
    padding: 30px 0 0;
    list-style: none;
    li {
      margin-bottom: 20px;
      a {
        color: #2a2a2a;
        text-decoration: none;
        font-size: 21px;
        font-weight: 300;
      }
    }
  }
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      allWordpressAcfOptions {
        edges {
          node {
            id
            options {
              main_menu_links {
                link {
                  title
                  url
                  target
                }
              }
            }
          }
        }
      }
    }
  `)

  const menuItems =
    data.allWordpressAcfOptions.edges[0].node.options.main_menu_links
  let classes = []

  const [activeHeader, setActiveHeader] = useState(false)
  const [activeMobileMenu, setActiveMobileMenu] = useState(false)

  function toggleMobileMenu() {
    if (activeMobileMenu == true) {
      // close menu
      setActiveMobileMenu(false)
    } else {
      setActiveMobileMenu(true)
    }
  }
  useEffect(() => {
    // Update the header class
    window.addEventListener("scroll", () => {
      if (window.scrollY > 40) {
        setActiveHeader(true)
      } else {
        setActiveHeader(false)
      }
    })
  }, [])
  return (
    <HeaderContainer className={activeHeader ? "active" : ""}>
      <Wrapper flex>
        <div className="logo-wrap">
          <Link to="/">
            <Logo />
          </Link>
        </div>

        <div className="links-wrap">
          <nav className="desktop-nav">
            <ul>
              {menuItems.map(item => {
                const link = item.link
                return (
                  <li>
                    <Link to={link.url}>{link.title}</Link>
                  </li>
                )
              })}
            </ul>
          </nav>

          <div className="mobile-trigger-container">
            <button
              className={
                activeMobileMenu
                  ? "hamburger hamburger--slider is-active"
                  : "hamburger hamburger--slider"
              }
              onClick={toggleMobileMenu}
            >
              <div className="hamburger-box">
                <div className="hamburger-inner"></div>
              </div>
            </button>
          </div>
        </div>
      </Wrapper>
      <MobileMenuContainer className={activeMobileMenu ? "active" : ""}>
        <Wrapper>
          <ul>
            {menuItems.map(item => {
              const link = item.link
              return (
                <li>
                  <Link to={link.url}>{link.title}</Link>
                </li>
              )
            })}
          </ul>
        </Wrapper>
      </MobileMenuContainer>
    </HeaderContainer>
  )
}

export default Header
